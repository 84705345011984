/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
;(window as any).__Zone_disable_customElements = true
;(window as any).__Zone_disable_requestAnimationFrame = true // disable patch requestAnimationFrame
// ;(window as any).__Zone_disable_on_property = true // disable patch onProperty such as onclick
// disable patch specified eventNames
// ;(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mouseenter', 'mouseleave', 'mousemove', 'mouseover', 'mouseout', 'mousewheel']
// ;(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mouseenter', 'mouseleave', 'mousemove', 'mouseover', 'mouseout', 'mousewheel']

export type {}
